/**
 * Created by jerry on 2020/03/5.
 * 储值卡分类api
 */
import * as API from '@/api/index'

export default {
    // 储值分类列表
    getSavingCardCategory: params => {
        return API.POST('api/savingCardCategory/all', params)
    },
    //新增储值卡分类
    createSavingCardCategory: params => {
        return API.POST('api/savingCardCategory/create', params)
    },
    //编辑储值卡分类
    updateSavingCardCategory:params => {
        return API.POST('api/savingCardCategory/update', params)
    },
    //储值卡分类顺序调整
    moveSavingCardCategory:params => {
        return API.POST('api/savingCardCategory/move', params)
    },

}